import { Tab } from 'react-ui-kit-exante';
import styled from 'styled-components';

export const TabsContainer = styled.div`
  position: relative;
  display: flex;
  position: sticky;
  top: 76px;
  z-index: 11;
  background: ${({ theme }) => theme.color.bg.primary};
  padding: 0 24px;
  height: 56px;
  align-items: flex-end;
  border-bottom: 2px solid ${({ theme }) => theme.color.line.primary};
  flex: 1 1 auto;

  & > div:first-of-type {
    transform: translateY(2px);
  }
`;

export const TabItem = styled(Tab)<{
  error?: string | undefined;
  disabled: boolean;
}>`
  color: ${({ error, theme }) =>
    !error ? theme.color.typo.primary : theme.color.typo.radical} !important;

  cursor: ${({ disabled }) =>
    disabled ? 'not-allowed' : 'pointer'} !important;
`;

export const ChildrenContainer = styled.div`
  position: absolute;
  right: 24px;
  bottom: 12px;
`;
