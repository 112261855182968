import { useRef } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import { InstrumentType } from '~/types/models';

import {
  BoolRow,
  BoolTable,
  Date,
  DateTime,
  Select,
  TextInput,
} from '../components';
import { TypedTabs } from '../constants';
import { InstrumentFormProps } from '../types';

import { Container, Column, Title } from './styled';

const Identifiers = ({
  options,
  type,
}: InstrumentFormProps & { type: InstrumentType }) => {
  const { current: issuerTypeOptions } = useRef<ISelectOption[]>([
    { value: 'government', label: 'Government' },
    { value: 'corporate', label: 'Corporate' },
    { value: 'municipal', label: 'Municipal' },
    { value: 'other', label: 'Other' },
  ]);

  const { current: spreadTypeOptions } = useRef<ISelectOption[]>([
    { value: 'FORWARD', label: 'Forward' },
    { value: 'REVERSE', label: 'Reverse' },
  ]);

  const { current: futureTypeOptions } = useRef<ISelectOption[]>([
    { value: 'INDEX', label: 'Index' },
    { value: 'SINGLE STOCK', label: 'Single Stock' },
    { value: 'COMMODITIES', label: 'Commodities' },
    { value: 'CURRENCY', label: 'Currency' },
    { value: 'DEBT', label: 'Debt' },
    { value: 'SWAP', label: 'Swap' },
    { value: 'OTHER', label: 'Other' },
  ]);

  const { current: exerciseStyleOptions } = useRef<ISelectOption[]>([
    { value: 'EUROPEAN', label: 'European' },
    { value: 'AMERICAN', label: 'American' },
    { value: 'BERMUDAN', label: 'Bermudan' },
  ]);

  const { current: marginingStyleOptions } = useRef<ISelectOption[]>([
    { value: 'FUTURES', label: 'Futures' },
    { value: 'EQUITY', label: 'Equity' },
  ]);

  const { current: calculationModelOptions } = useRef<ISelectOption[]>([
    { value: 'black76', label: 'Black 76' },
    { value: 'black-scholes', label: 'Black Scholes' },
  ]);

  const { current: forwardModeOptions } = useRef<ISelectOption[]>([
    { value: 'ifp', label: 'IFP' },
    { value: 'underlying-discounted', label: 'Underlying Discounted' },
    { value: 'underlying', label: 'Underlying' },
  ]);

  return (
    <Container>
      <Column>
        <Title>Identifiers</Title>
        <TextInput path="identifiers.ISIN">ISIN</TextInput>
        <TextInput path="identifiers.SEDOL">SEDOL</TextInput>
        <TextInput path="identifiers.CUSIP">CUSIP</TextInput>
        <TextInput path="identifiers.FIGI">FIGI</TextInput>
        <TextInput path="identifiers.RIC">RIC</TextInput>
        <TextInput path="identifiers.B2N">B2N</TextInput>

        <Title>Dates</Title>
        <DateTime path="expiry">Expiry</DateTime>
        <DateTime path="lastTrading">Last Trading</DateTime>
        <DateTime path="lastAvailable">Last Available</DateTime>

        {(type === 'BOND' || type === 'STOCK') && (
          <DateTime path="issueDate">Issue Date</DateTime>
        )}

        <Title>Quote Filters</Title>
        <TextInput path="quoteFilters.maxSpread">Max Spread</TextInput>

        <TextInput path="country">Country</TextInput>
        {type === 'BOND' && (
          <TextInput path="countryRisk">Country Risk</TextInput>
        )}

        <Title>Instrument Rating</Title>
        <TextInput path="rating.fitch">Fitch</TextInput>
        <TextInput path="rating.moodys">Moodys</TextInput>
        <TextInput path="rating.snp">Snp</TextInput>

        <Title>{TypedTabs[type as InstrumentType]}</Title>

        {type === 'BOND' && (
          <>
            <TextInput path="actPreFirstDays">Act Pre First Days</TextInput>
            <TextInput path="paymentFrequency">Payment Frequency</TextInput>

            <Date path="maturityDate">Maturity Date</Date>

            <Select path="issuerType" options={issuerTypeOptions}>
              Issuer Type
            </Select>

            <BoolTable>
              <BoolRow path="bondCalcDataSettings.aciEnabled">
                Aci Enabled
              </BoolRow>
              <BoolRow path="bondCalcDataSettings.ytmEnabled">
                Ytm Enabled
              </BoolRow>
              <BoolRow path="processAccruedInterest">
                Process Accrued Interest
              </BoolRow>
            </BoolTable>
          </>
        )}

        {type === 'CALENDAR_SPREAD' && (
          <>
            <Date path="farMaturityDate">Far Maturity Date</Date>
            <Date path="firstNoticeDay">First Notice Day</Date>
            <Date path="nearMaturityDate">Near Maturity Date</Date>
            <TextInput path="risk.slippage">Risk</TextInput>

            <Select path="spreadType" options={spreadTypeOptions}>
              Spread Type
            </Select>

            <TextInput path="underlyingId.id">Underlying Id</TextInput>

            <TextInput path="underlyingId.type">Underlying Type</TextInput>
          </>
        )}

        {type === 'CFD' && <TextInput path="risk.slippage">Risk</TextInput>}

        {type === 'FOREX' && (
          <>
            <Select path="baseCurrency" options={options.currency}>
              Base Currency
            </Select>
            <BoolTable>
              <BoolRow path="useInCrossrates">Use In Crossrates</BoolRow>
            </BoolTable>
          </>
        )}

        {type === 'FUND' && (
          <>
            <TextInput path="risk.slippage">Risk</TextInput>
            <TextInput path="structuralAssetId">Structural Asset Id</TextInput>
          </>
        )}

        {type === 'FUTURE' && (
          <>
            <Select path="baseCurrency" options={options.currency}>
              Base Currency
            </Select>
            <Date path="firstNoticeDay">First Notice Day</Date>
            <Date path="maturityDate">Maturity Date</Date>
            <Select path="futureType" options={futureTypeOptions}>
              Future Type
            </Select>
            <TextInput path="maturityName">Maturity Name</TextInput>
            <TextInput path="risk.slippage">Risk</TextInput>
            <TextInput path="underlyingId.id">Underlying Id</TextInput>

            <TextInput path="underlyingId.type">Underlying Type</TextInput>
            <BoolTable>
              <BoolRow path="isCashSettlement">Is Cash Settlement</BoolRow>
              <BoolRow path="isForward">Is Forward</BoolRow>
            </BoolTable>
          </>
        )}

        {type === 'FX_SPOT' && (
          <>
            <Select path="baseCurrency" options={options.currency}>
              Base Currency
            </Select>
            <TextInput path="maturityName">Maturity Name</TextInput>
            <TextInput path="risk.slippage">Risk</TextInput>
          </>
        )}

        {type === 'OPTION' && (
          <>
            <Select path="baseCurrency" options={options.currency}>
              Base Currency
            </Select>
            <Select path="exerciseStyle" options={exerciseStyleOptions}>
              Exercise Style
            </Select>
            <Select path="marginingStyle" options={marginingStyleOptions}>
              Margining Style
            </Select>
            <Select path="marginingStyle" options={calculationModelOptions}>
              Calculation Model
            </Select>
            <Select path="marginingStyle" options={forwardModeOptions}>
              Forward Mode
            </Select>

            <TextInput path="extendedMarginPeriod">
              Extended Margin Period
            </TextInput>
            <TextInput path="risk.slippage">Risk</TextInput>
            <TextInput path="strikeToUnderlyingScale">
              Strike To Underlying Scale
            </TextInput>
            <TextInput path="underlyingId.id">Underlying Id</TextInput>
            <TextInput path="underlyingId.type">Underlying Type</TextInput>

            <TextInput path="zeusSettings.maxRelSpread">
              Max Rel Spread
            </TextInput>
            <TextInput path="zeusSettings.minMpiSpread">
              Min Mpi Spread
            </TextInput>
            <TextInput path="zeusSettings.outlierQuantile">
              Outlier Quantile
            </TextInput>

            <Date path="firstNoticeDay">First Notice Day</Date>
            <Date path="maturityDate">Maturity Date</Date>

            <BoolTable>
              <BoolRow path="isCashSettlement">Is Cash Settlement</BoolRow>
              <BoolRow path="zeusSettings.adjustStrikePriceToUnderlying">
                Adjust Strike Price To Underlying
              </BoolRow>
              <BoolRow path="zeusSettings.enabled">Enabled</BoolRow>
              <BoolRow path="zeusSettings.useMedian">Use Median</BoolRow>
            </BoolTable>
          </>
        )}

        {type === 'STOCK' && <TextInput path="risk.slippage">Risk</TextInput>}
      </Column>
    </Container>
  );
};

export default Identifiers;
