import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import { InstrumentRouteParams } from '~/pages/Instruments/types';
import { NAV } from '~/pages/routing';

import { FormProps } from '../types';

const useAccountForm = (stackValue: FormProps['stackValue'], id?: string) => {
  const navigate = useNavigate();
  const { id: instrumentId } = useParams<InstrumentRouteParams>();
  const { state, dispatch } = useContext(InstrumentContext);

  const { name, sortable } = useMemo(() => {
    const result = stackValue.find((item) => item.key === id);

    return { name: result?.label, sortable: result?.sortable };
  }, [id, stackValue]);

  const handleAppend = () => {
    dispatch({
      type: InstrumentActions.AppendOverride,
      payload: {
        key: id,
        path: 'brokers.accounts',
        data: {
          order: stackValue.length,
        },
      },
    });
  };

  const handleDelete = () => {
    dispatch({
      type: InstrumentActions.Remove,
      payload: `brokers.accounts.${id}`,
    });
  };

  const handleClose = () => {
    dispatch({
      type: InstrumentActions.DiscardChanges,
      payload: 'brokers.accounts',
    });

    navigate(`${NAV.INSTRUMENTS}/${instrumentId}/overrides`);
  };

  const handleSave = () => {
    navigate(`${NAV.INSTRUMENTS}/${instrumentId}/overrides`);
  };

  return {
    name,
    sortable,

    dirty: state.dirty,
    isAbstract: state.values.isAbstract,

    handleAppend,
    handleClose,
    handleDelete,
    handleSave,
  };
};

export default useAccountForm;
