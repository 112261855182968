import { FC } from 'react';

import { DefaultThemeProvider } from 'theme';

import { Root } from './PanelGroup.styled';
import { IPanelGroupProps } from './types';

export const PanelGroup: FC<Partial<IPanelGroupProps>> = ({
  children,
  className = '',
  equalWidth = false,
  panelsPerLine = 0,
}) => {
  return (
    <DefaultThemeProvider>
      <Root
        className={className}
        equalWidth={equalWidth}
        panelsPerLine={panelsPerLine}
      >
        {children}
      </Root>
    </DefaultThemeProvider>
  );
};
