import { styled } from 'react-ui-kit-exante';

export const Container = styled('form')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  padding: '0 24px',
}));

export const Header = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '70px',
  justifyContent: 'space-between',
}));

export const Gateways = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: 0,
}));

export const GatewaysProps = styled('div')(() => ({
  display: 'grid',
  flex: '1 1 auto',
  gridTemplateColumns: '240px 1fr',
  height: 0,
  overflowY: 'auto',
}));

export const FeedProps = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '222px 222px 222px',
  paddingBottom: '24px',
}));

export const Actions = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

export const Title = styled('span')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '24px',
}));
