import { Splits, StringArray, TreePath } from '../components';

import { Container, Column } from './styled';

const ArrayFields = () => {
  return (
    <Container>
      <Column>
        <TreePath path="treePath" />
        <StringArray path="treePathOverride" placeholder="Enter key word">
          Tree Path Override
        </StringArray>
        <StringArray path="tags" placeholder="Enter instrument tag">
          Instrument tags
        </StringArray>
        <Splits />
        <StringArray
          path="stampDuty.countryOfIncorporation"
          placeholder="Enter key word"
        >
          Country of incorporation
        </StringArray>
      </Column>
    </Container>
  );
};

export default ArrayFields;
