import { Panel, PanelGroup, styled } from 'react-ui-kit-exante';

import { AddField } from './components/AddLocale/AddField';

export const AddFieldContainer = styled(AddField)(() => ({
  marginBottom: '32px',
}));

export const Control = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
  marginTop: '16px',
}));

export const Controls = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
}));

export const PanelContainer = styled(Panel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
}));

export const PanelGroupContainer = styled(PanelGroup)(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  gridTemplateColumns: '2fr minmax(550px, 1fr)',
}));
