import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

import { KitTheme, styled } from 'theme';

import { IUISelectedListItemTextProps } from './types';

const getListItemBorderColor = (
  selected: boolean,
  warned: boolean,
  t: KitTheme,
) => {
  if (warned && selected) {
    return t.color.input.warning;
  }

  return selected ? t.color.typo.action : 'transparent';
};

const getListItemColor = (selected: boolean, warned: boolean, t: KitTheme) => {
  if (warned) {
    return t.color.input.warning;
  }

  return selected ? t.color.typo.primary : t.color.typo.action;
};

export const ListItemButtonStyled = styled(ListItemButton)(() => ({
  padding: '4px 0',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const ListItemTextStyled = styled(
  ListItemText,
)<IUISelectedListItemTextProps>(({ selected, theme, warned }) => ({
  fontFamily: theme?.font.main,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '24px',
  padding: '0 16px 0 24px',
  borderLeft: '2px solid transparent',
  borderColor: getListItemBorderColor(selected, !!warned, theme),
  color: getListItemColor(selected, !!warned, theme),
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:hover': {
    color: warned ? theme?.color.input.warning : theme?.color.typo.primary,
  },
  '& .searchHighlight': {
    backgroundColor: theme?.color.notifications.warning,
  },
})) as React.ComponentType<IUISelectedListItemTextProps>;
