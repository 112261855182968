import { useContext, useMemo } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';

import { BoolValue } from './types';
import { getPayload, getValue } from './utils';

const useBoolRow = (path: string, inheritable?: boolean) => {
  const { state, dispatch } = useContext(InstrumentContext);

  const value = useMemo(() => {
    return {
      inherit: getValue(getInheritValue<boolean>(path, state.parents)),
      self: getValue(getSelfValue<boolean>(path, state.values)),
    };
  }, [path, state]);

  const isInherited = value.inherit !== 'unset' && inheritable;
  const disabled = state.saveStatus.pending;

  const resetButton = useMemo(() => {
    return {
      isActive: Boolean(isInherited && value.self !== 'unset'),
      value: value.inherit === 'yes' ? 'Yes' : 'No',
    };
  }, [value, isInherited]);

  const handleChange = (payload: BoolValue) => {
    if (!disabled) {
      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: getPayload(payload) },
      });
    }
  };

  const handleReset = () => {
    if (isInherited && !disabled) {
      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: undefined },
      });
    }
  };

  const isChecked = (key: 'yes' | 'no'): boolean => {
    return (
      (value.inherit === key && value.self === 'unset') || value.self === key
    );
  };

  const getViewProp = (key: 'yes' | 'no'): 'primary' | 'secondary' => {
    if (!isInherited) {
      return 'primary';
    }

    return value.inherit === key && value.self === 'unset'
      ? 'secondary'
      : 'primary';
  };

  const error = state.errors.get(path);

  return {
    isInherited,

    value,
    resetButton,

    isChecked,
    getViewProp,

    error,
    disabled,

    handleChange,
    handleReset,
  };
};

export default useBoolRow;
