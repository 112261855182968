import { FC, useEffect, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { IconButton, Input, Loader } from 'react-ui-kit-exante';

import { DeleteDialog } from '~/components/DeleteDialog';

import { BrokersForm, FeedsForm, SectionsForm } from './components';
import { useExchangeForm } from './hooks';
import {
  Actions,
  FormContainer,
  Header,
  Identifiers,
  IdentifiersForm,
  LoaderContainer,
  Main,
  SubTitle,
  Title,
} from './styled';
import { ExchangeFormProps } from './types';

const ExchangeForm: FC<ExchangeFormProps> = ({
  brokers,
  feeds,
  onClose,
  onDelete,
  onSuccess,
  onDirty,
}) => {
  const {
    id,
    addBroker,
    addFeed,
    feedsArray,
    deletingStatus,
    fetchingStatus,
    form,
    deleteExchangeItem,
    isNew,
    isSaveDisabled,
    title,
    onSubmit,
    brokersFieldControl,
    setSelectedBrokerId,
    setSelectedFeedId,
    brokersList,
    brokersArray,
    brokersOptions,
    feedsFieldControl,
    feedsOptions,
    feedsList,
    isDirty,
    addSection,
    sectionsFieldControl,
    sectionsList,
    selectedSectionId,
    setSelectedSectionId,
  } = useExchangeForm(brokers, feeds, { onDelete, onSuccess });

  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  useEffect(() => {
    if (brokersList.length > 0) {
      setSelectedBrokerId(brokersList?.at(0)?.id || null);
    }
  }, [brokersList]);

  useEffect(() => {
    if (feedsList.length > 0) {
      setSelectedFeedId(feedsList?.at(0)?.id || null);
    }
  }, [feedsList]);

  useEffect(() => {
    if (sectionsList.length > 0) {
      setSelectedSectionId(sectionsList[0].id);
    }
  }, [sectionsList]);

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  if (fetchingStatus.isPending) {
    return (
      <LoaderContainer>
        <Loader size="l" isCentered />
      </LoaderContainer>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        <FormContainer onSubmit={onSubmit}>
          <Header>
            <Title>{title}</Title>
            <Actions>
              <IconButton
                disabled={isSaveDisabled}
                iconColor="action"
                iconName="SaveIcon"
                iconSize={24}
                label="Save"
                type="submit"
              />

              {!isNew && (
                <IconButton
                  iconColor="radical"
                  iconName="DeleteIcon"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}

              <IconButton
                iconColor="secondary"
                iconName="CloseIcon"
                iconSize={24}
                onClick={onClose}
              />
            </Actions>
          </Header>
          <Main>
            <Controller
              key="name"
              name="name"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  label="Name *"
                  sx={{ width: '100%' }}
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                  message={fieldState.error?.message}
                />
              )}
            />

            <Controller
              key="exchangeName"
              name="exchangeName"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  label="Exchange Name *"
                  sx={{ width: '100%' }}
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                  message={fieldState.error?.message}
                />
              )}
            />

            <Controller
              key="treeName"
              name="treeName"
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Tree Name"
                  sx={{ width: '100%' }}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              key="description"
              name="description"
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Description"
                  sx={{ width: '100%' }}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Main>
          <Identifiers>
            <SubTitle>Identifiers</SubTitle>
            <IdentifiersForm>
              <Controller
                key="figi"
                name="figi"
                control={form.control}
                render={({ field }) => (
                  <Input
                    label="FIGI"
                    sx={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key="mic"
                name="mic"
                control={form.control}
                render={({ field }) => (
                  <Input
                    label="MIC"
                    sx={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </IdentifiersForm>
          </Identifiers>

          <BrokersForm
            addBroker={addBroker}
            brokersArray={brokersArray}
            brokersFieldControl={brokersFieldControl}
            brokersOptions={brokersOptions}
            form={form}
          />

          <FeedsForm
            addFeed={addFeed}
            feedsArray={feedsArray}
            feedsFieldControl={feedsFieldControl}
            feedsOptions={feedsOptions}
            form={form}
          />

          <SectionsForm
            addSection={addSection}
            brokersOptions={brokersOptions}
            feedsOptions={feedsOptions}
            form={form}
            sectionsFieldControl={sectionsFieldControl}
            selectedSectionId={selectedSectionId}
            sectionsList={sectionsList}
            setSelectedSectionId={setSelectedSectionId}
          />
        </FormContainer>
      </FormProvider>
      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={deleteExchangeItem}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default ExchangeForm;
