import startCase from 'lodash/startCase';
import { FC, useState, ChangeEvent, useMemo } from 'react';
import { Tab, TabPanel, Tabs } from 'react-ui-kit-exante';

import { CodeEditor } from '~/components/CodeEditor/CodeEditor';
import { IEventTabsProps } from '~/pages/StaticData/Main/types';
import { getJsonString } from '~/utils/getJsonString';
import { sortArrayByOrder } from '~/utils/sortArrayByOrder';

import { TAB_ORDER } from '../constants';

import { TabsWrapper } from './EventTabs.styled';

export const EventTabs: FC<IEventTabsProps> = ({ event }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (_: ChangeEvent<EventTarget>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const action = event.result?.action;

  const sortedArray = useMemo(
    () => (action && sortArrayByOrder(Object.keys(action), TAB_ORDER)) || [],
    [action],
  );

  const tabs = sortedArray.map((key, index) => {
    const dataArray =
      action && action[key].map((str: string) => getJsonString(str));
    const dataString = dataArray?.join('\n');

    return (
      <TabPanel key={key} value={selectedTab} index={index}>
        <CodeEditor
          readOnly
          mode="json"
          value={dataString}
          name="static-data-event"
        />
      </TabPanel>
    );
  });

  return tabs?.length > 0 ? (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        sx={(theme) => ({
          pl: '24px',
          backgroundColor: theme.color.bg.primary,
        })}
      >
        {sortedArray.map((key: string) => {
          const label = `${startCase(key)} ${event?.count[key] ?? ''}`;

          return <Tab key={key} label={label} />;
        })}
      </Tabs>
      <TabsWrapper>{tabs}</TabsWrapper>
    </>
  ) : null;
};
