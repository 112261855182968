import { Panel, PanelGroup, styled } from 'react-ui-kit-exante';

export const Controls = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
}));

export const PanelContainer = styled(Panel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
}));

export const PanelGroupContainer = styled(PanelGroup)(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  gridTemplateColumns: '2fr minmax(550px, 1fr)',
}));

export const Image = styled('div')(() => ({
  paddingTop: '15px',
}));
