import { useEffect, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { IconButton, Input, Loader } from 'react-ui-kit-exante';

import { DeleteDialog } from '~/components/DeleteDialog';

import { Gateways } from './Gateways';
import useBrokerForm from './hooks/useBrokerForm';
import {
  Actions,
  Body,
  FormContainer,
  Header,
  LoaderContainer,
  Title,
} from './styled';
import { BrokerFormProps } from './types';

const BrokerForm: React.FC<BrokerFormProps> = ({
  onClose,
  onDelete,
  onDirty,
  onSuccess,
}) => {
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  const {
    deleteBrokerProvider,
    deletingStatus,
    fetchingStatus,
    form,
    id,
    isDirty,
    isNew,
    isSaveDisabled,
    onSubmit,
    title,
  } = useBrokerForm({ onDelete, onSuccess });

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  if (fetchingStatus.isPending) {
    return (
      <LoaderContainer>
        <Loader size="l" isCentered />
      </LoaderContainer>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        <FormContainer onSubmit={onSubmit}>
          <Header>
            <Title>{title}</Title>
            <Actions>
              <IconButton
                disabled={isSaveDisabled}
                iconColor="action"
                iconName="SaveIcon"
                iconSize={24}
                label="Save"
                type="submit"
              />

              {!isNew && (
                <IconButton
                  iconColor="radical"
                  iconName="DeleteIcon"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}

              <IconButton
                iconColor="secondary"
                iconName="CloseIcon"
                iconSize={24}
                onClick={onClose}
              />
            </Actions>
          </Header>

          <Body>
            <Controller
              key="name"
              name="name"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  fullWidth
                  label="Name *"
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                  message={fieldState.error?.message}
                />
              )}
            />

            <Controller
              key="providerType"
              name="providerType"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  fullWidth
                  label="Provider Type"
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                  message={fieldState.error?.message}
                />
              )}
            />
          </Body>

          <Gateways form={form} />
        </FormContainer>
      </FormProvider>
      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={deleteBrokerProvider}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default BrokerForm;
