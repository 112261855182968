/* eslint-disable no-underscore-dangle */

import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { SortableListItem } from '~/components/SortableList/types';
import useFetchStatus from '~/hooks/useFetchStatus';
import { IFeedGateway, IFeedProvider } from '~/pages/Feeds/types';
import { symbolDBService } from '~/services/symbolDB.service';
import { getUUIDKey } from '~/utils/uuid';

import { FeedRouteParams, FormValues, GatewayValues } from '../types';
import validationSchema from '../validation';

const defaultValues: FormValues = {
  name: '',
  providerType: '',
  gateways: [],
};

const plainGateways = (
  gateways: Record<string, IFeedGateway> | null,
): Array<GatewayValues> => {
  if (!gateways) {
    return [];
  }

  return Object.keys(gateways).map((key) => ({
    key,
    name: gateways[key].name,
    source: gateways[key].feedSource,
    address: gateways[key].feedAddress,
    environment: gateways[key].environment,
    delayed: gateways[key].delayedFeed ? '1' : '0',
    description: gateways[key].description,
    capacity: gateways[key].capacity,
  }));
};

const mapGateways = (
  gateways?: Array<GatewayValues>,
): Record<string, IFeedGateway> | undefined => {
  if (!gateways || gateways.length === 0) {
    return undefined;
  }

  return gateways.reduce<Record<string, IFeedGateway>>((result, gateway) => {
    return {
      ...result,
      [gateway.key]: {
        delayedFeed: !!gateway.delayed,
        environment: gateway.environment,
        feedAddress: gateway.address,
        feedSource: gateway.source,
        name: gateway.name,
        description: gateway.description || undefined,
        capacity: gateway.capacity || undefined,
      },
    };
  }, {});
};

const getFormValues = (feed?: IFeedProvider): FormValues => {
  if (!feed) {
    return defaultValues;
  }

  return {
    name: feed.name,
    providerType: feed.providerType,
    gateways: plainGateways(feed.gateways),
  };
};

const useFeedForm = (
  feeds: Array<IFeedProvider>,
  onSubmit: (payload: IFeedProvider) => void,
  onDelete?: (id: string) => void,
  onError?: () => void,
) => {
  const { id } = useParams<FeedRouteParams>();

  const [savingStatus, savingStatusActions] = useFetchStatus();
  const [deletingStatus, deletingStatusActions] = useFetchStatus();

  const feed = feeds.find((item) => item._id === id);
  const isNew = !feed?._id;
  const defaultTitle = feed?.name || 'Unnamed Feed';
  const title = isNew ? 'New Feed' : defaultTitle;

  const form = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form;

  const gatewaysArray = form.getValues('gateways');
  const gatewaysFieldControl = useFieldArray({ name: 'gateways', control });

  const gatewaysList = useMemo<Array<SortableListItem>>(() => {
    if (!gatewaysArray) {
      return [];
    }

    return gatewaysArray.map((gateway) => ({
      name: gateway.name,
      id: gateway.key,
    }));
  }, [gatewaysArray]);

  const handleAppendGateway = () => {
    const index = gatewaysFieldControl.fields.length + 1;

    gatewaysFieldControl.prepend([
      {
        key: getUUIDKey(),
        name: index < 2 ? `New` : `New ${index}`,
        source: '',
        address: '',
        environment: '',
        delayed: '0',
        description: '',
      },
    ]);
  };

  const handleSave = async (values: FormValues) => {
    const payload = {
      name: values.name,
      providerType: values.providerType || undefined,
      gateways: mapGateways(values.gateways),
    };

    savingStatusActions.handleStart();

    try {
      const data = id
        ? await symbolDBService().updateFeedProvider(id, payload)
        : await symbolDBService().createFeedProvider(payload);

      form.reset();
      onSubmit(data);
      savingStatusActions.handleSuccess();
    } catch (e: unknown) {
      if (onError) {
        onError();
      }

      const isExistError =
        (e as AxiosError).response?.data?.description?.name ===
        'already exists';

      if (isExistError) {
        Notification.error({ title: 'Feed with this name already exist' });
      }

      savingStatusActions.handleError(e as AxiosError, { quiet: isExistError });
    }
  };

  const handleDeleteFeed = async () => {
    if (id && onDelete) {
      try {
        deletingStatusActions.handleStart();
        await symbolDBService().deleteFeedProvider(id);

        onDelete(id);
        deletingStatusActions.handleSuccess();
      } catch (e: unknown) {
        deletingStatusActions.handleError(e as AxiosError);

        if (onError) {
          onError();
        }
      }
    }
  };

  useEffect(() => {
    reset(getFormValues(feed));
  }, [feed, reset]);

  return {
    form,
    gatewaysArray,
    gatewaysFieldControl,
    gatewaysList,
    handleAppendGateway,
    handleDeleteFeed,
    id,
    isDirty,
    isNew,
    isSaveDisabled: !isDirty || savingStatus.isPending,
    savingStatus,
    deletingStatus,
    onSubmit: handleSubmit(handleSave),
    title,
  };
};

export default useFeedForm;
