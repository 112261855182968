import { useRef, useState } from 'react';
import { Checkbox, IconButton } from 'react-ui-kit-exante';

import { ReactComponent as FolderIcon } from '~/assets/i/Folder.svg';
import { ReactComponent as InstrumentIcon } from '~/assets/i/Instrument.svg';
import { ReactComponent as SyncIcon } from '~/assets/i/Sync.svg';
import { ToggleGroup, InputSearch } from '~/components';

import { OrderBy } from '../../types';

import {
  Container,
  LegendItem,
  Legend,
  Header,
  Body,
  Content,
  Options,
  RefreshButton,
} from './styled';
import { FilterProps, GroupByOptions } from './types';

const Filter = ({
  state,
  onHideExpiredChange,
  onOrderByChange,
  onSearch,
  onTradingChange,
  onRefresh,
}: FilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { current: groupByOptions } = useRef<GroupByOptions>([
    {
      value: 'name',
      label: 'Name',
    },
    {
      value: 'creationTime',
      label: 'Creation Time',
    },
  ]);

  const handleGroupByChange = (value: Array<OrderBy>) => {
    onOrderByChange(value.at(1));
  };

  return (
    <Container>
      <InputSearch
        minChars={2}
        initialValue={state.search}
        onChange={onSearch}
      />

      <Content>
        <Header>
          <span>Filter</span>
          <IconButton
            iconColor="primary"
            iconName={isOpen ? 'ArrowDownSmallIcon' : 'ArrowTopSmallIcon'}
            iconSize={24}
            onClick={() => setIsOpen(!isOpen)}
          />
        </Header>

        {isOpen && (
          <Body>
            <ToggleGroup<OrderBy>
              options={groupByOptions}
              value={[state.orderBy]}
              onChange={handleGroupByChange}
            />

            <Options>
              <Checkbox
                checked={state.hideExpired}
                onChange={onHideExpiredChange}
                label="Hide expired"
              />

              <Checkbox
                checked={state.trading}
                onChange={onTradingChange}
                label="Trading"
              />

              <RefreshButton onClick={onRefresh}>
                <SyncIcon />
                <span>Update</span>
              </RefreshButton>
            </Options>

            <Legend>
              <LegendItem>
                <FolderIcon />
                <InstrumentIcon />
                <span>Trading</span>
              </LegendItem>

              <LegendItem>
                <FolderIcon />
                <InstrumentIcon />
                <span>Non Trading</span>
              </LegendItem>
            </Legend>
          </Body>
        )}
      </Content>
    </Container>
  );
};

export default Filter;
