import { FC } from 'react';
import AceEditor, { IAceEditorProps } from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/webpack-resolver';
import { styled } from 'react-ui-kit-exante';

import { DEFAULT_EDITOR_PARAMS } from './constants';
import { CodeEditorContainer } from './styled';

const CodeEditorComponent: FC<IAceEditorProps> = (props) => {
  return (
    <CodeEditorContainer>
      <AceEditor {...DEFAULT_EDITOR_PARAMS} {...props} />
    </CodeEditorContainer>
  );
};

export const CodeEditor = styled(CodeEditorComponent)(({ theme }) => ({
  '.ace_content, .ace_gutter': {
    backgroundColor: theme.color.bg.promo,
  },
}));
