import { RadioGroup as RadioGroupUI } from '@mui/material';

import { DefaultThemeProvider } from 'theme';

import { Radio } from './Radio';
import {
  FormControlLabelStyled,
  FormLabelStyled,
  Root,
} from './RadioGroup.styled';
import { RadioGroupProps } from './types';

export const RadioGroup = ({
  isRow = true,
  radioOptions,
  handleChange,
  value,
  warning,
  formLabel,
}: RadioGroupProps) => {
  return (
    <DefaultThemeProvider>
      <Root>
        {formLabel && <FormLabelStyled>{formLabel}</FormLabelStyled>}
        <RadioGroupUI row={isRow} onChange={handleChange} value={value}>
          {radioOptions.map((option) => (
            <FormControlLabelStyled
              key={option.value}
              value={option.value}
              control={<Radio warning={warning} />}
              label={option.label}
              disabled={option.disabled}
              checked={value === option.value}
              warning={warning}
            />
          ))}
        </RadioGroupUI>
      </Root>
    </DefaultThemeProvider>
  );
};
