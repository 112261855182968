import { Loader, styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  color: theme.color.typo.primary,
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: 'calc(100vh - 70px)',
  overflowY: 'auto',
}));

export const Header = styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.color.line.primary}`,
  display: 'flex',
  flex: '0 0 auto',
  fontSize: '32px',
  height: '96px',
  padding: '0 24px',
}));

export const Body = styled('div')(() => ({
  display: 'grid',
  flex: '1 1 auto',
  gridTemplateColumns: '350px 1fr',
}));

export const Content = styled('div')(() => ({
  display: 'grid',
  height: 'calc(100vh - 24px)',
  overflowY: 'auto',
}));

export const Loading = styled(Loader)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  maxHeight: '70vh',
}));
