import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { useFetchStatus } from '~/hooks';
import { symbolDBService } from '~/services/symbolDB.service';
import { Currency } from '~/types/models';
import { sanitizedValues } from '~/utils/sanitizedValues';

import { CurrencyRouteParams } from '../types';
import getValidationSchema from '../validation';

const defaultValues: Currency = {
  _id: '',
  _rev: '',
  _creationTime: '',
  _lastUpdateTime: '',
  icon: '',
  description: '',
  leverageRate: 0,
  leverageRateShort: 0,
  currencySymbol: '',
  fractionDigits: 0,
  extremeLeverageRate: 0,
  extremeLeverageRateShort: 0,
  code: 0,
  weight: 0,
  isCrypto: false,
  isNav: false,
};

const getFormValues = (currency?: Currency): Currency => {
  if (!currency) {
    return defaultValues;
  }

  return currency;
};

const useCurrencyForm = (
  currencies: Array<Currency>,
  onSubmit: (payload: Currency) => void,
  onDelete?: (id: string) => void,
  onError?: () => void,
) => {
  const { id } = useParams<CurrencyRouteParams>();

  const [savingStatus, savingStatusActions] = useFetchStatus();
  const [deletingStatus, deletingStatusActions] = useFetchStatus();

  const currency = currencies.find((item) => item._id === id);
  const isNew = !currency?._id;
  const defaultTitle = currency?._id || 'Unnamed Currency';
  const title = isNew ? 'New Currency' : defaultTitle;

  const currencyIDs = currencies.reduce((acc, curr) => {
    acc.add(curr._id);

    return acc;
  }, new Set<string>());

  const validationSchema = useMemo(
    () => getValidationSchema(currencyIDs),
    [currencyIDs],
  );
  const form = useForm<Currency>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form;

  const handleSave = async (values: Currency) => {
    savingStatusActions.handleStart();

    try {
      const data = id
        ? await symbolDBService().updateCurrency(sanitizedValues(values), id)
        : await symbolDBService().createCurrency(sanitizedValues(values));

      form.reset();
      onSubmit(data);
      savingStatusActions.handleSuccess();
    } catch (e: unknown) {
      if (onError) {
        onError();
      }

      const isExistError =
        (e as AxiosError).response?.data?.description?.name ===
        'already exists';

      if (isExistError) {
        Notification.error({ title: 'Currency with this name already exist' });
      }

      savingStatusActions.handleError(e as AxiosError, { quiet: isExistError });
    }
  };

  const handleDeleteCurrency = async () => {
    if (id && onDelete) {
      try {
        deletingStatusActions.handleStart();
        await symbolDBService().deleteCurrency(id);

        onDelete(id);
        deletingStatusActions.handleSuccess();
      } catch (e: unknown) {
        deletingStatusActions.handleError(e as AxiosError);

        if (onError) {
          onError();
        }
      }
    }
  };

  useEffect(() => {
    reset(getFormValues(currency));
  }, [currency, reset]);

  return {
    form,
    handleDeleteCurrency,
    id,
    isDirty,
    isNew,
    isSaveDisabled: !isDirty || savingStatus.isPending,
    savingStatus,
    deletingStatus,
    onSubmit: handleSubmit(handleSave),
    title,
  };
};

export default useCurrencyForm;
