import {
  RuleSchedule,
  ScheduleForm,
  ScheduleRule,
  ScheduleRuleForm,
  ScheduleRuleFormMatchItem,
  ScheduleRuleMatchItem,
  SchedulesResponseItem,
} from '~/pages/Schedules/types';
import { DurationTypeValue } from '~/types/models';

import { defaultValues } from './constants';

export const getMatchValues = (
  match: ScheduleRuleMatchItem,
): ScheduleRuleFormMatchItem => {
  return {
    ...match,
    year: match.year === '*' ? '' : match.year,
    isWeekDay: !match.day.dayOfMonth,
  };
};

export const getRuleValues = (rule: ScheduleRule): ScheduleRuleForm => {
  return {
    ...rule,
    matches: rule.matches.map((match) => getMatchValues(match)),
    schedule: rule.schedule || [],
  };
};

export const getFormValues = (
  schedule: SchedulesResponseItem | null,
): ScheduleForm => {
  if (!schedule) {
    return defaultValues;
  }

  return {
    ...defaultValues,

    name: schedule.name,
    description: schedule.description,
    timezone: schedule.timezone,
    rules: schedule.rules.map((rule) => getRuleValues(rule)),
  };
};

export const mapMatchToPayload = (
  match: ScheduleRuleFormMatchItem,
): ScheduleRuleMatchItem => {
  return {
    ...match,
    year: match.year || '*',
    day: {
      ...(match.isWeekDay && {
        weekDay: match.day.weekDay,
        occurrence: match.day.occurrence,
      }),
      ...(!match.isWeekDay && { dayOfMonth: match.day.dayOfMonth }),
    },
  };
};

const getDurationTypes = (
  value?: Partial<DurationTypeValue>,
): Partial<DurationTypeValue> | undefined => {
  if (!value) {
    return undefined;
  }

  const keys = Object.keys(value) as Array<keyof DurationTypeValue>;

  return keys.reduce<Partial<DurationTypeValue>>((result, key) => {
    if (value[key] && value[key]?.length) {
      return { ...result, [key]: value[key] };
    }

    return result;
  }, {});
};

const mapScheduleToPayload = (item: RuleSchedule): RuleSchedule => {
  return {
    ...item,
    availableOrderDurationTypes: getDurationTypes(
      item.availableOrderDurationTypes,
    ),
  };
};

export const mapFormRuleToPayload = (rule: ScheduleRuleForm): ScheduleRule => {
  return {
    ...rule,
    matches: rule.matches?.map((match) => mapMatchToPayload(match)) || [],
    schedule:
      rule.schedule?.map((schedule) => mapScheduleToPayload(schedule)) || [],
  };
};
