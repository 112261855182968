import { IAceEditorProps } from 'react-ace';

export const DEFAULT_EDITOR_PARAMS: IAceEditorProps = {
  mode: 'javascript',
  theme: 'monokai',
  editorProps: {
    $blockScrolling: true,
  },
  fontSize: 18,
  width: '100%',
  showPrintMargin: false,
  setOptions: {
    tabSize: 2,
    useWorker: false,
  },
};
