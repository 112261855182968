import { diff } from 'json-diff';
import { useContext, useMemo } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';

export const stringify = (obj: Record<string, unknown>): string => {
  return JSON.stringify(obj, null, 2);
};

export const useSourceView = () => {
  const { state } = useContext(InstrumentContext);

  return useMemo(
    () => [
      stringify(state.values), // value
      stringify(diff(state.ref, state.values)), // diff
    ],
    [state.values, state.ref],
  );
};

export default useSourceView;
