import { Link } from 'react-router-dom';
import styled from 'styled-components';

type StyledTitleProps = {
  variant?: 'h1' | 'h2';
};

export const Container = styled.div`
  padding: 24px;
`;

export const ContentPreview = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 24px;
`;

export const Title = styled.span<StyledTitleProps>`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: ${({ variant = 'h1' }) => (variant === 'h1' ? 20 : 18)}px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditButton = styled(Link)`
  & svg {
    fill: ${({ theme }) => theme.color.icon.ghost};
    transform: scale(0.8);
  }

  &:hover svg {
    fill: ${({ theme }) => theme.color.icon.primary};
  }
`;

export const Section = styled.div`
  display: grid;
  grid-template-rows: 16px 1fr;
  gap: 12px;
`;

export const ItemTitle = styled(Link)`
  color: ${({ theme }) => theme.color.typo.primary};
  text-decoration: none;
  border-bottom: 1px dashed ${({ theme }) => theme.color.typo.ghost};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TargetName = styled.div`
  font-size: 13px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.typo.inverse};
  background-color: #3a4fce;
  border-radius: 4px;
  padding: 0 8px;
`;

export const ItemHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ItemLink = styled.a`
  color: ${({ theme }) => theme.color.typo.action};
  background-color: ${({ theme }) => theme.color.bg.basic};
  font-size: 13px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 8px 0 2px;
  text-decoration: none;

  & svg {
    fill: ${({ theme }) => theme.color.typo.action};
    transform: scale(0.7);
  }

  &:hover {
    color: ${({ theme }) => theme.color.typo.primary};
  }

  &:hover svg {
    fill: ${({ theme }) => theme.color.typo.primary};
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

export const Placeholder = styled.div`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 13px;
`;
