import { FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { IconButton, Loader } from 'react-ui-kit-exante';

import { DeleteDialog } from '~/components/DeleteDialog';

import { MainForm, RulesForm } from './components';
import { useSchedule } from './hooks';
import {
  Actions,
  Container,
  FormContainer,
  Header,
  LoaderContainer,
  Rules,
  Title,
} from './styled';
import { ScheduleFormProps } from './types';

const ScheduleForm: FC<ScheduleFormProps> = ({
  onClose,
  onDelete,
  onSuccess,
  onDirty,
}) => {
  const {
    deletingStatus,
    fetchingStatus,
    form,
    handleDelete,
    handleAppendRule,
    isNew,
    isSaveDisabled,
    savingStatus,
    title,
    onSubmit,
    isDirty,
    id,
  } = useSchedule({ onDelete, onSuccess });

  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  if (fetchingStatus.isPending) {
    return (
      <Container>
        <LoaderContainer>
          <Loader size="l" isCentered />
        </LoaderContainer>
      </Container>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        <FormContainer onSubmit={onSubmit}>
          <Header>
            <Title>{title}</Title>
            <Actions>
              <IconButton
                disabled={isSaveDisabled || savingStatus.isPending}
                iconColor={isSaveDisabled ? 'secondary' : 'action'}
                iconName="SaveIcon"
                iconSize={24}
                label="Save"
                type="submit"
              />

              {!isNew && (
                <IconButton
                  iconColor="radical"
                  iconName="DeleteIcon"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}

              <IconButton
                iconColor="secondary"
                iconName="CloseIcon"
                iconSize={24}
                onClick={onClose}
              />
            </Actions>
          </Header>

          <MainForm form={form} />

          <Rules>
            <Header>
              <Title>Rules</Title>
              <Actions>
                <IconButton
                  iconColor="action"
                  iconName="AddIcon"
                  iconSize={24}
                  label="Add Rule"
                  onClick={handleAppendRule}
                />
              </Actions>
            </Header>
            <RulesForm form={form} />
          </Rules>
        </FormContainer>
      </FormProvider>
      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={handleDelete}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default ScheduleForm;
