import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ISelectOption, Notification } from 'react-ui-kit-exante';

import { SortableListItem } from '~/components/SortableList/types';
import useFetchStatus from '~/hooks/useFetchStatus';
import { IBrokerProvider } from '~/pages/Brokers/types';
import { IFeedProvider } from '~/pages/Feeds/types';
import { symbolDBService } from '~/services/symbolDB.service';
import { ExchangeItem } from '~/types/models';
import getErrorDescription from '~/utils/getErrorDescription';
import { getUUIDKey } from '~/utils/uuid';

import { ExchangeFormValue, ExchangeParams } from '../types';
import { getFormValue, mapFormValueToPayload } from '../utils';
import validationSchema from '../validation';

type UseExchangeHandlers = {
  onSuccess: (payload: ExchangeItem) => void;
  onDelete?: (id: string) => void;
  onError?: () => void;
};

const useExchangeForm = (
  brokers: Array<IBrokerProvider>,
  feeds: Array<IFeedProvider>,
  { onSuccess, onDelete, onError }: UseExchangeHandlers,
) => {
  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();
  const [deletingStatus, deletingStatusActions] = useFetchStatus();

  const [selectedBrokerId, setSelectedBrokerId] = useState<string | null>(null);
  const [selectedFeedId, setSelectedFeedId] = useState<string | null>(null);
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(
    null,
  );

  const form = useForm<ExchangeFormValue>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const feedsFieldControl = useFieldArray({
    name: 'feeds',
    control: form.control,
  });

  const brokersFieldControl = useFieldArray({
    name: 'brokers',
    control: form.control,
  });

  const sectionsFieldControl = useFieldArray({
    name: 'sections',
    control: form.control,
  });

  const { id } = useParams<ExchangeParams>();
  const [exchange, setExchange] = useState<ExchangeItem | null>(null);

  const getExchangeItem = async () => {
    if (id) {
      fetchingStatusActions.handleStart();
      try {
        const data = await symbolDBService().getExchangeItem(id);
        setExchange(data);
        form.reset(getFormValue(data));
        fetchingStatusActions.handleSuccess();
      } catch (e) {
        fetchingStatusActions.handleError(e as AxiosError);
      }
    } else {
      form.reset(getFormValue(null));
    }
  };

  const deleteExchangeItem = async () => {
    if (id && onDelete) {
      try {
        deletingStatusActions.handleStart();
        await symbolDBService().deleteExchangeItem(id);
        onDelete(id);
        deletingStatusActions.handleSuccess();
      } catch (e) {
        deletingStatusActions.handleError(e as AxiosError);

        if (onError) {
          onError();
        }
      }
    }
  };

  const saveExchangeItem = async (value: ExchangeFormValue) => {
    const payload = mapFormValueToPayload(value);

    try {
      const data = id
        ? await symbolDBService().updateExchangeItem(id, payload)
        : await symbolDBService().createExchangeItem(payload);

      form.reset(value);

      onSuccess(data);
    } catch (e: unknown) {
      if (onError) {
        onError();
      }

      const isExistError =
        (e as AxiosError).response?.data?.description?.name ===
        'already exists';

      if (isExistError) {
        Notification.error({ title: 'Exchange with this name already exist' });

        return;
      }

      Notification.error({
        title: getErrorDescription(
          (e as AxiosError).response?.data?.description,
        ).join(' : '),
      });
    }
  };

  const title = useMemo(() => {
    if (!id) {
      return 'New Exchange';
    }

    const exchangeTitle = exchange?.name || 'Unnamed Exchange';

    return fetchingStatus.isSucceed ? exchangeTitle : '';
  }, [id, fetchingStatus.isSucceed]);

  const {
    formState: { isDirty },
    handleSubmit,
  } = form;

  const getBrokerLabel = (brokerId?: string) => {
    const broker = brokers.find((item) => item._id === brokerId);

    return broker?.name || 'Select broker';
  };

  const getFeedLabel = (brokerId?: string) => {
    const feed = feeds.find((item) => item._id === brokerId);

    return feed?.name || 'Select feed';
  };

  const brokersArray = form.watch('brokers');
  const brokersList = useMemo<Array<SortableListItem>>(() => {
    if (!brokersArray) {
      return [];
    }

    return brokersArray.map((broker) => ({
      id: Math.random().toString(),
      name: getBrokerLabel(broker.brokerId),
    }));
  }, [brokersArray, brokers]);

  const feedsArray = form.watch('feeds');
  const feedsList = useMemo<Array<SortableListItem>>(() => {
    if (!feedsArray) {
      return [];
    }

    return feedsArray.map((broker) => ({
      id: Math.random().toString(),
      name: getFeedLabel(broker.brokerId),
    }));
  }, [feedsArray, feeds]);

  const sectionsArray = form.getValues('sections');
  const sectionsList = useMemo<Array<SortableListItem>>(() => {
    if (!sectionsArray) {
      return [];
    }

    return sectionsArray.map((section) => ({
      name: section.name,
      id: section.id,
    }));
  }, [sectionsArray]);

  const brokersOptions = useMemo<Array<ISelectOption>>(
    () => brokers.map((broker) => ({ value: broker._id, label: broker.name })),
    [brokers],
  );

  const feedsOptions = useMemo<Array<ISelectOption>>(
    () => feeds.map((broker) => ({ value: broker._id, label: broker.name })),
    [feeds],
  );

  const addBroker = () => {
    brokersFieldControl.prepend({
      id: Math.random().toString(),
      brokerId: '',
      override: '',
    });
  };

  const addFeed = () => {
    feedsFieldControl.prepend({
      id: Math.random().toString(),
      brokerId: '',
      override: '',
    });
  };

  const addSection = () => {
    const index = sectionsFieldControl.fields.length + 1;

    sectionsFieldControl.prepend({
      id: getUUIDKey(false, true),
      name: index < 2 ? 'New' : `New ${index}`,
      description: '',
      scheduleId: '',
      submarket: '',
      brokers: [],
      feeds: [],
    });
  };

  useEffect(() => {
    getExchangeItem();
  }, [id]);

  return {
    id,
    addBroker,
    addFeed,
    brokersArray,
    feedsList,
    brokersFieldControl,
    brokersList,
    feedsArray,
    deleteExchangeItem,
    deletingStatus,
    feedsFieldControl,
    fetchingStatus,
    form,
    getBrokerLabel,
    isNew: !id,
    isSaveDisabled: !isDirty,
    onSubmit: handleSubmit(saveExchangeItem),
    selectedBrokerId,
    selectedFeedId,
    setSelectedBrokerId,
    setSelectedFeedId,
    feedsOptions,
    brokersOptions,
    title,
    isDirty,
    addSection,
    sectionsFieldControl,
    sectionsList,
    selectedSectionId,
    setSelectedSectionId,
  };
};

export default useExchangeForm;
