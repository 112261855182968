import { FC } from 'react';

import { DefaultThemeProvider } from 'theme';

import { ReactComponent as RadioActiveIcon } from '../../../../assets/RadioActiveButton.svg';
import { ReactComponent as RadioActiveWarningButton } from '../../../../assets/RadioActiveWarningButton.svg';
import { ReactComponent as RadioIcon } from '../../../../assets/RadioButton.svg';
import { ReactComponent as WarningRadioIcon } from '../../../../assets/WarningRadioButton.svg';

import { RadioStyled } from './Radio.styled';
import { IRadioProps } from './Radio.types';

export const Radio: FC<IRadioProps> = (props) => {
  const { warning } = props;

  return (
    <DefaultThemeProvider>
      <RadioStyled
        checkedIcon={
          warning ? <RadioActiveWarningButton /> : <RadioActiveIcon />
        }
        icon={warning ? <WarningRadioIcon /> : <RadioIcon />}
        {...props}
      />
    </DefaultThemeProvider>
  );
};
