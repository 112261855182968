import { ValueConfig } from '../types';

const ACCOUNTS: ValueConfig[] = [
  {
    path: 'enabled',
    type: 'boolean',
  },
  {
    path: 'allowFallback',
    type: 'boolean',
  },
  {
    path: 'executionSchemeId',
    type: 'executionSchemeID',
  },
  {
    path: 'constraints.allowedTags',
    type: 'array',
  },
  {
    path: 'constraints.allowedIntervalTypes',
    type: 'array',
  },
  {
    path: 'constraints.allowedEntities',
    type: 'array',
  },
  {
    path: 'constraints.forbiddenTags',
    type: 'array',
  },
  {
    path: 'constraints.forbiddenSources',
    type: 'array',
  },
  {
    path: 'constraints.forbiddenEntities',
    type: 'array',
  },
  {
    path: 'constraints.shortSaleAvailable',
    type: 'boolean',
  },
  {
    path: 'constraints.brokerAvailabilityRequired',
    type: 'boolean',
  },
  {
    path: 'constraints.minQuantity',
    type: 'number',
  },
  {
    path: 'constraints.maxQuantity',
    type: 'number',
  },
  {
    path: 'constraints.forbiddenSide',
    type: 'string',
  },
  {
    path: 'constraints.availableOrderDurationTypes',
    type: 'availableOrderDurationTypes',
  },
];

export default ACCOUNTS;
