import { InstrumentData, InstrumentState } from './types';

export const defaultValues: InstrumentData = {
  _creationTime: '',
  _id: '',
  _lastUpdateTime: '',
  _rev: '',

  path: [],
  isAbstract: false,
  isTrading: false,
  name: '',
};

export const initialState: InstrumentState = {
  affectedSymbolsAmount: 0,
  isAffectedSymbolsDialogShown: false,

  parents: [],
  values: defaultValues,
  ref: defaultValues,

  errors: new Map([]),

  ready: false,
  dirty: false,

  fetchStatus: {
    error: null,
    pending: false,
    succeed: false,
  },

  saveStatus: {
    error: false,
    pending: false,
    succeed: false,
  },

  deleteStatus: {
    error: false,
    pending: false,
    succeed: false,
  },

  affectedSymbolsStatus: {
    error: false,
    pending: false,
    succeed: false,
  },
};
