import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { IconButton, Input, Select } from 'react-ui-kit-exante';

import { DeleteDialog } from '~/components/DeleteDialog';
import { SortableList } from '~/components/SortableList';
import { PROVIDER_TYPE } from '~/constants';

import { GatewayFormPlaceholder, GatewayForm } from './components';
import { useFeedForm } from './hooks';
import {
  Actions,
  Container,
  FeedProps,
  Gateways,
  GatewaysProps,
  Header,
  Title,
} from './styled';
import { FeedFormProps } from './types';

const FeedForm: React.FC<FeedFormProps> = ({
  feeds,
  onClose,
  onDelete,
  onSuccess,
  onDirty,
}) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  const {
    deletingStatus,
    form,
    gatewaysFieldControl,
    gatewaysList,
    handleAppendGateway,
    handleDeleteFeed,
    isNew,
    isDirty,
    id,
    isSaveDisabled,
    onSubmit,
    savingStatus,
    title,
  } = useFeedForm(feeds, onSuccess, onDelete, () =>
    setIsDeleteDialogShown(false),
  );

  const selectedIndex = useMemo<number>(
    () => gatewaysList.findIndex((item) => item.id === selectedId),
    [gatewaysList, selectedId],
  );

  const providerTypeOptions = PROVIDER_TYPE.map((provider) => ({
    value: provider,
    label: provider,
  }));

  useEffect(() => {
    if (gatewaysList.length > 0) {
      setSelectedId(gatewaysList[0].id);
    }
  }, [gatewaysList]);

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  return (
    <>
      <FormProvider {...form}>
        <Container onSubmit={onSubmit}>
          <Header>
            <Title>{title}</Title>
            <Actions>
              <IconButton
                disabled={isSaveDisabled || savingStatus.isPending}
                iconColor="action"
                iconName="SaveIcon"
                iconSize={24}
                label="Save"
                type="submit"
              />

              {!isNew && (
                <IconButton
                  iconColor="radical"
                  iconName="DeleteIcon"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}

              <IconButton
                iconColor="secondary"
                iconName="CloseIcon"
                iconSize={24}
                onClick={onClose}
              />
            </Actions>
          </Header>
          <FeedProps>
            <Controller
              key="name"
              name="name"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  label="Name *"
                  sx={{ mt: '16px', width: '100%' }}
                  error={Boolean(fieldState.error?.message)}
                  value={field.value || ''}
                  onChange={field.onChange}
                  message={
                    fieldState.error?.message && 'Name is required field'
                  }
                />
              )}
            />

            <Controller
              key="providerType"
              name="providerType"
              control={form.control}
              render={({ field }) => (
                <Select
                  label="Provider type"
                  onChange={field.onChange}
                  options={providerTypeOptions}
                  placeholder="Provider type"
                  sx={{
                    minWidth: 220,
                    mt: '16px',
                    width: '100%',
                  }}
                  value={field.value || ''}
                />
              )}
            />
          </FeedProps>
          <Gateways>
            <Header>
              <Title>Gateways</Title>
              <Actions>
                <IconButton
                  iconColor="action"
                  iconName="AddIcon"
                  iconSize={24}
                  label="Add Gateway"
                  onClick={handleAppendGateway}
                />
              </Actions>
            </Header>
            {selectedIndex === -1 && <GatewayFormPlaceholder />}
            {selectedIndex !== -1 && (
              <GatewaysProps>
                <SortableList
                  items={gatewaysList}
                  selectedId={selectedId}
                  onSelect={setSelectedId}
                />
                <GatewayForm
                  index={selectedIndex}
                  form={form}
                  onDelete={gatewaysFieldControl.remove}
                />
              </GatewaysProps>
            )}
          </Gateways>
        </Container>
      </FormProvider>

      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={handleDeleteFeed}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default FeedForm;
