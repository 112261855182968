import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import useFetchStatus from '~/hooks/useFetchStatus';
import {
  ScheduleForm,
  ScheduleParams,
  SchedulesResponseItem,
} from '~/pages/Schedules/types';
import { symbolDBService } from '~/services/symbolDB.service';
import getErrorDescription from '~/utils/getErrorDescription';

import { defaultRule } from '../constants';
import { getFormValues, mapFormRuleToPayload } from '../utils';
import validationSchema from '../validation';

type UseScheduleProps = {
  onSuccess: (payload: SchedulesResponseItem) => void;
  onDelete?: (id: string) => void;
  onError?: () => void;
};

const useSchedule = ({ onSuccess, onDelete, onError }: UseScheduleProps) => {
  const form = useForm<ScheduleForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const rulesFieldControl = useFieldArray({
    name: 'rules',
    control: form.control,
  });

  const { id } = useParams<ScheduleParams>();
  const [schedule, setSchedule] = useState<SchedulesResponseItem | null>(null);

  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();
  const [savingStatus, savingStatusActions] = useFetchStatus();
  const [deletingStatus, deletingStatusActions] = useFetchStatus();

  const getScheduleItem = async () => {
    if (id) {
      fetchingStatusActions.handleStart();
      try {
        const data = await symbolDBService().getScheduleItem(id);
        setSchedule(data);
        form.reset(getFormValues(data));
        fetchingStatusActions.handleSuccess();
      } catch (e) {
        fetchingStatusActions.handleError(e as AxiosError);
      }
    } else {
      form.reset(getFormValues(null));
    }
  };

  const handleDelete = async () => {
    if (id && onDelete) {
      try {
        deletingStatusActions.handleStart();
        await symbolDBService().deleteScheduleItem(id);
        onDelete(id);
        deletingStatusActions.handleSuccess();
      } catch (e) {
        deletingStatusActions.handleError(e as AxiosError);

        if (onError) {
          onError();
        }
      }
    }
  };

  const handleSave = async (values: ScheduleForm) => {
    const payload: Partial<SchedulesResponseItem> = {
      name: values.name,
      timezone: values.timezone,
      ...(values.description && {
        description: values.description,
      }),
      rules: values.rules.map((rule) => mapFormRuleToPayload(rule)),
    };

    savingStatusActions.handleStart();

    try {
      const data = id
        ? await symbolDBService().updateScheduleItem(id, payload)
        : await symbolDBService().createScheduleItem(payload);

      form.reset(getFormValues({ ...payload, ...data }));

      onSuccess({ ...payload, ...data });
      savingStatusActions.handleSuccess();
    } catch (e: unknown) {
      if (onError) {
        onError();
      }

      const isExistError =
        (e as AxiosError).response?.data?.description?.name ===
        'already exists';

      if (isExistError) {
        Notification.error({ title: 'Schedule with this name already exist' });
      }

      Notification.error({
        title: getErrorDescription(
          (e as AxiosError).response?.data?.description,
        ).join(' : '),
      });

      savingStatusActions.handleError(e as AxiosError, { quiet: true });
    }
  };

  const title = useMemo(() => {
    if (!id) {
      return 'New Schedule';
    }

    const scheduleTitle = schedule?.name || 'Unnamed Schedule';

    return fetchingStatus.isSucceed ? scheduleTitle : '';
  }, [id, fetchingStatus.isSucceed]);

  const {
    formState: { dirtyFields },
    handleSubmit,
  } = form;

  const isDirty = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    getScheduleItem();
  }, [id]);

  const handleAppendRule = () => {
    const index = rulesFieldControl.fields.length + 1;

    rulesFieldControl.prepend([
      {
        ...defaultRule,
        description: index < 2 ? `New rule` : `New rule ${index}`,
      },
    ]);
  };

  return {
    deletingStatus,
    fetchingStatus,
    form,
    handleDelete,
    handleAppendRule,
    isNew: !id,
    isSaveDisabled: !isDirty || savingStatus.isPending,
    onSubmit: handleSubmit(handleSave),
    savingStatus,
    schedule,
    title,
    id,
    isDirty,
  };
};

export default useSchedule;
