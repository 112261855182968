import { useCallback } from 'react';
import { Table, IconButton } from 'react-ui-kit-exante';

import { useLocalizations } from '../Localizations/useLocalizations';

import { ThemeForm } from './ThemeForm/ThemeForm';
import { COLUMNS, DISPLAYED_COLUMN_KEYS } from './constants';
import { PanelContainer, PanelGroupContainer } from './styled';
import { useSDBThemes } from './useSDBThemes';

export const Themes = () => {
  const {
    themes,
    isThemesLoading,
    isNewTheme,
    isShowTheme,
    theme,
    brandings,
    tags,
    handlers: {
      getThemes,
      createNewTheme,
      getSingleTheme,
      deleteTheme,
      resetThemeState,
    },
  } = useSDBThemes();

  const { localizationKeys } = useLocalizations();

  const onDelete = useCallback(
    async (id: string) => {
      await deleteTheme(id);
      await getThemes();
    },
    [deleteTheme, getThemes],
  );

  return (
    <>
      <PanelContainer
        action={
          <IconButton
            data-test-id="themes__button--add"
            iconColor="action"
            iconName="AddIcon"
            iconSize={24}
            label="Add theme"
            onClick={createNewTheme}
          />
        }
        title="Themes"
      />
      <PanelGroupContainer equalWidth>
        <Table
          tableId="themes"
          columns={COLUMNS}
          data={themes}
          handleRowClick={({ _id }) => getSingleTheme(_id)}
          isHiddenColumnSelect
          hasPagination
          isLoading={isThemesLoading}
          displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
          isFlexLayout
        />
        {isShowTheme && theme !== null && (
          <ThemeForm
            isNewTheme={isNewTheme}
            onClose={resetThemeState}
            theme={theme}
            onUpdateThemes={getThemes}
            onDelete={onDelete}
            localizationKeys={localizationKeys}
            brandings={brandings}
            tags={tags}
          />
        )}
      </PanelGroupContainer>
    </>
  );
};
