import { AxiosError } from 'axios';
import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import {
  createInstrument,
  fetchLegacyInstrument,
  saveInstrument,
  deleteInstrument,
  checkLegacyInstrumentDependencies,
} from '~/api/SymbolDBService';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getErrorDescription,
  getErrorTitle,
  getInheritValue,
  getInstrumentData,
  getInstrumentPayload,
  getSelfValue,
  isJSON,
} from '~/pages/Instruments/context/utils';
import { InstrumentRouteParams } from '~/pages/Instruments/types';
import { NAV } from '~/pages/routing';
import { InstrumentType } from '~/types/models';

import { InstrumentFormProps } from './types';

const useInstrumentForm = (
  dependencies: InstrumentFormProps['dependencies'],
  onForceRefresh?: InstrumentFormProps['onForceRefresh'],
) => {
  const nav = useNavigate();
  const { id } = useParams<InstrumentRouteParams>();
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const [isSetNameDialogShown, setIsSetNameDialogShown] = useState(false);
  const [isChild, setIsChild] = useState(false);

  const { state, dispatch } = useContext(InstrumentContext);

  const type = useMemo(() => {
    return (
      getSelfValue<InstrumentType>('type', state.values) ||
      getInheritValue<InstrumentType>('type', state.parents)
    );
  }, [state]);

  const handleClose = () => {
    nav(NAV.INSTRUMENTS);
  };

  const fetchFormValues = async () => {
    dispatch({ type: InstrumentActions.FetchStart });

    try {
      const response = await fetchLegacyInstrument(id);

      if (response.length > 0) {
        dispatch({
          payload: getInstrumentData(response),
          type: InstrumentActions.FetchSucceed,
        });
      } else {
        dispatch({
          type: InstrumentActions.FetchError,
          payload: 'DELETED',
        });
      }
    } catch (e) {
      dispatch({
        type: InstrumentActions.FetchError,
        payload: e as AxiosError,
      });
    }
  };

  const handleSubmit = async () => {
    dispatch({ type: InstrumentActions.HideAffectedSymbolsDialog });

    if (state.errors.size > 0) {
      Notification.error({
        title: 'Form is invalid',
      });

      return;
    }

    dispatch({ type: InstrumentActions.SaveStart });

    const payload = getInstrumentPayload({ ...state.values }, dependencies);

    try {
      if (id && !isChild) {
        await saveInstrument(id, payload);

        onForceRefresh?.();
      } else if (onForceRefresh) {
        await createInstrument(payload);

        onForceRefresh();
        setIsChild(false);
      }

      dispatch({ type: InstrumentActions.SaveSucceed });

      Notification.success({
        title: 'Successfully',
      });
    } catch (error) {
      dispatch({
        type: InstrumentActions.SaveError,
        payload: error as AxiosError,
      });

      const errorObj =
        isJSON((error as AxiosError).response?.data?.message) &&
        JSON.parse((error as AxiosError).response?.data?.message);

      Notification.error({
        title: getErrorTitle(errorObj?.message),
        description: getErrorDescription(errorObj?.description),
      });
    }
  };

  const handleDelete = async () => {
    if (id && onForceRefresh) {
      dispatch({ type: InstrumentActions.DeleteStart });

      try {
        const resetExpanded = true;
        nav(NAV.INSTRUMENTS);
        await deleteInstrument(id);

        Notification.success({
          title: 'Successfully deleted',
        });

        dispatch({ type: InstrumentActions.DeleteSucceed });
        onForceRefresh(resetExpanded);
      } catch (error) {
        dispatch({
          type: InstrumentActions.DeleteError,
          payload: error as AxiosError,
        });

        setIsDeleteDialogShown(false);

        const message =
          (error as AxiosError).response?.data?.description?.reason ||
          (error as AxiosError).response?.data?.message;

        Notification.error({
          title: getErrorTitle(message),
          description: getErrorDescription(
            (error as AxiosError).response?.data?.description,
          ),
        });
      }
    }
  };

  const handleCreateChild = () => {
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: 'isAbstract', value: false },
    });
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: 'isTrading', value: false },
    });

    setIsChild(true);
  };

  const handleDeleteDecline = () => {
    setIsDeleteDialogShown(false);
  };
  const handleDeleteRequest = () => {
    setIsDeleteDialogShown(true);
  };

  const handleSaveAsNew = async (name: string) => {
    if (state.errors.size > 0) {
      Notification.error({
        title: 'Form is invalid',
      });

      return;
    }

    dispatch({ type: InstrumentActions.SaveStart });
    setIsSetNameDialogShown(false);

    const path = [...state.values.path];
    path?.pop();

    const payload = getInstrumentPayload(
      { ...state.values, name, path, isTrading: false },
      dependencies,
    );

    try {
      if (onForceRefresh) {
        await createInstrument(payload);
        onForceRefresh();
      }

      dispatch({ type: InstrumentActions.SaveSucceed });

      Notification.success({
        title: 'Successfully',
      });
    } catch (error) {
      dispatch({
        type: InstrumentActions.SaveError,
        payload: error as AxiosError,
      });

      const message =
        (error as AxiosError).response?.data?.description?.reason ||
        (error as AxiosError).response?.data?.message;

      Notification.error({
        title: getErrorTitle(message),
        description: getErrorDescription(
          (error as AxiosError).response?.data?.description,
        ),
      });
    }
  };

  const handleSaveAsRequest = () => {
    setIsSetNameDialogShown(true);
  };
  const handleDeclineSaveAsRequest = () => {
    setIsSetNameDialogShown(false);
  };

  const handleGetAffectedSymbols = async (event: FormEvent) => {
    try {
      event.preventDefault();

      if (id && !isChild) {
        dispatch({ type: InstrumentActions.AffectedSymbolsStart });

        const { affectedSymbols } = await checkLegacyInstrumentDependencies(id);

        if (affectedSymbols === 0) {
          handleSubmit();

          return;
        }

        dispatch({
          type: InstrumentActions.AffectedSymbolsSucceed,
          payload: affectedSymbols,
        });
      } else {
        handleSubmit();
      }
    } catch (error) {
      dispatch({
        type: InstrumentActions.AffectedSymbolsError,
        payload: error as AxiosError,
      });
    }
  };

  const handleDeclineSubmit = () => {
    dispatch({ type: InstrumentActions.HideAffectedSymbolsDialog });
  };

  useEffect(() => {
    if (id) {
      fetchFormValues();
    } else {
      dispatch({ type: InstrumentActions.SetReady });
    }
  }, [id]);

  return {
    type,
    isCreating: !id,
    isDeleteDialogShown,
    isSetNameDialogShown,
    isChild,

    state,
    dispatch,

    handleClose,
    handleCreateChild,
    handleDeclineSubmit,
    handleDelete,
    handleDeleteDecline,
    handleDeleteRequest,
    handleGetAffectedSymbols,
    handleSaveAsRequest,
    handleDeclineSaveAsRequest,
    handleSaveAsNew,
    handleSubmit,
  };
};

export default useInstrumentForm;
