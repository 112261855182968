import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Notification,
  SelectedListWithSearch,
  IUIISelectedListWithSearchProps,
  IOption,
} from 'react-ui-kit-exante';

import { symbolDBService } from '../../services/symbolDB.service';

import { Placeholder } from './Placeholder';
import { SymbolTypeForm } from './SymbolTypeForm/SymbolTypeForm';
import {
  Content,
  Head,
  SelectedListBlock,
  SymbolTypesList,
  Wrapper,
} from './styled';
import { ISymbolTypeFormValues } from './types';

const initialValue = {
  name: '',
  displayName: '',
  expiredTtl: 0,
};

export const SymbolTypes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [symbolTypes, setSymbolTypes] = useState<Array<ISymbolTypeFormValues>>(
    [],
  );
  const [selected, setSelected] = useState<string | null>(null);

  const [isShowSymbolType, setIsShowSymbolType] = useState(false);
  const [symbolType, setSymbolType] = useState<
    ISymbolTypeFormValues | typeof initialValue
  >(initialValue);

  const openSymbolTypeForm = (_: IOption, id: string) => {
    setIsShowSymbolType(true);

    // eslint-disable-next-line no-underscore-dangle
    const symbolTypeItem = symbolTypes.find((item) => item._id === id);

    if (symbolTypeItem) {
      setSymbolType(symbolTypeItem);
    }

    setSelected(id);
  };

  const closeSymbolTypeForm = () => {
    setIsShowSymbolType(false);
    setSymbolType(initialValue);
    setSelected(null);
  };

  const getSymbolTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await symbolDBService().getSymbolTypes();
      setSymbolTypes(response);
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getSymbolTypes();
  }, [getSymbolTypes]);

  const options = useMemo(() => {
    return symbolTypes.map(
      ({ _id: id, displayName: name, ...rest }: ISymbolTypeFormValues) => ({
        ...rest,
        id,
        name,
      }),
    ) as IUIISelectedListWithSearchProps['options'];
  }, [symbolTypes]);

  return (
    <Wrapper>
      <Head title="Symbol types" />
      <Content>
        <SelectedListBlock>
          <SymbolTypesList>
            <SelectedListWithSearch
              isLoading={isLoading}
              onListItemClick={openSymbolTypeForm}
              options={options}
              selected={selected}
            />
          </SymbolTypesList>
        </SelectedListBlock>
        {isShowSymbolType && symbolType ? (
          <SymbolTypeForm
            onClose={closeSymbolTypeForm}
            symbolType={symbolType}
            onUpdateSymbolType={getSymbolTypes}
          />
        ) : (
          <Placeholder />
        )}
      </Content>
    </Wrapper>
  );
};
