import { useContext, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SortableStackValue } from '~/components';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import { InstrumentRouteParams } from '~/pages/Instruments/types';
import { NAV } from '~/pages/routing';

import getGateways, { getGatewayName } from '../../services/getGateways';
import { canBeSortable, hasInheritedValues, hasSelfValues } from '../../utils';

const useGatewaysList = (dependencies: InstrumentFormProps['dependencies']) => {
  const navigate = useNavigate();
  const { id } = useParams<InstrumentRouteParams>();
  const { pathname } = useLocation();
  const { state, dispatch } = useContext(InstrumentContext);

  const gateways = getGateways(state, dependencies);

  const value = useMemo(
    () =>
      gateways.map((item) => ({
        key: item.id,
        label: item.name,
        isBold: hasSelfValues(item, id),
        sortable: canBeSortable(state.values, `feeds.gateways.${item.id}`),
        withArrow: hasInheritedValues(item, id),
      })),
    [gateways, id],
  );

  const options = useMemo(() => {
    if (!dependencies.feedGateways) {
      return [];
    }

    return dependencies.feedGateways.map((result) => ({
      key: result._id,
      label: getGatewayName(result),
    }));
  }, [dependencies.feedGateways]);

  const selected = useMemo(() => {
    const idString = pathname.split('/').pop();

    return idString === 'gateways' ? undefined : idString;
  }, [pathname]);

  const handleChange = (_: SortableStackValue[], key: string) => {
    dispatch({
      type: InstrumentActions.AppendOverride,
      payload: {
        key,
        path: 'feeds.gateways',
        data: {
          order: value.length,
        },
      },
    });
  };

  const handleOrderChange = (
    indexA: number,
    indexB: number,
    payload: SortableStackValue[],
  ): void => {
    dispatch({
      type: InstrumentActions.StackOrderChange,
      payload: {
        path: 'feeds.gateways',
        nodes: [
          {
            key: payload[indexA]?.key,
            index: indexA,
          },
          {
            key: payload[indexB]?.key,
            index: indexB,
          },
        ],
      },
    });
  };

  const handleSelect = (key: string): void => {
    navigate(`${NAV.INSTRUMENTS}/${id}/overrides/feeds/gateways/${key}`);
  };

  return {
    value,
    options,
    selected,

    handleChange,
    handleOrderChange,
    handleSelect,
  };
};

export default useGatewaysList;
