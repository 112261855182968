import { IconButton, Tooltip } from 'react-ui-kit-exante';

import {
  Autocomplete,
  BoolRow,
  BoolTable,
} from '~/pages/Instruments/components/InstrumentForm/components';

import {
  Placeholder,
  FormContainer,
  Header,
  Title,
  Actions,
  Body,
} from '../styled';
import { FormProps } from '../types';

import useGatewayForm from './useGatewayForm';

const Form = ({ id, stackValue }: FormProps) => {
  const {
    dirty,
    name,
    sortable,

    handleAppend,
    handleClose,
    handleDelete,
    handleSave,
  } = useGatewayForm(stackValue, id);

  return (
    <FormContainer>
      <Header>
        <Title>{id && name && `Edit Gateway: ${name}`}</Title>
        <Actions>
          {id && name && (
            <>
              <Tooltip title="Apply changes and close form" placement="bottom">
                <IconButton
                  disabled={!dirty}
                  iconColor={dirty ? 'action' : 'secondary'}
                  iconName="SaveIcon"
                  iconSize={18}
                  label="OK"
                  onClick={handleSave}
                />
              </Tooltip>
              {!sortable && (
                <Tooltip
                  placement="bottom"
                  title="Add inherited Gateway to instrument"
                >
                  <IconButton
                    iconName="DownloadIcon"
                    iconSize={18}
                    onClick={handleAppend}
                  />
                </Tooltip>
              )}
            </>
          )}

          {id && name && sortable && (
            <Tooltip title="Delete Gateway from instrument" placement="bottom">
              <IconButton
                iconColor="radical"
                iconName="DeleteIcon"
                iconSize={18}
                onClick={handleDelete}
              />
            </Tooltip>
          )}

          <Tooltip title="Discard changes and close form" placement="bottom">
            <IconButton
              iconColor="secondary"
              iconName="CloseIcon"
              iconSize={18}
              onClick={handleClose}
            />
          </Tooltip>
        </Actions>
      </Header>

      {!id && <Placeholder>Select Gateway</Placeholder>}
      {id && !name && <Placeholder>Gateway is not exist</Placeholder>}

      {id && name && (
        <Body>
          <BoolTable>
            <BoolRow path={`feeds.gateways.${id}.enabled`}>Enabled</BoolRow>

            <BoolRow path={`feeds.gateways.${id}.allowFallback`}>
              Allow Fallback
            </BoolRow>
          </BoolTable>

          <Autocomplete path={`feeds.gateways.${id}.constraints.allowedTags`}>
            Allowed Tags
          </Autocomplete>

          <Autocomplete path={`feeds.gateways.${id}.constraints.forbiddenTags`}>
            Forbidden Tags
          </Autocomplete>
        </Body>
      )}
    </FormContainer>
  );
};

export default Form;
