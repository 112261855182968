import { useMemo, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTheme } from 'react-ui-kit-exante';
import { ThemeProvider } from 'styled-components';

import RegistrationSessionContainer from '~/components/RegistrationSessionContainer';

import { InstrumentForm, Placeholder, Sidebar } from './components';
import { routes } from './constants';
import { InstrumentContext, useInstrument } from './context';
import { Body, Container, Content, Loading } from './styled';
import { SidebarRef } from './types';
import useDependencies from './useDependencies';

const Instruments = () => {
  const theme = useTheme();
  const sidebarRef = useRef<SidebarRef>(null);

  const { state, dispatch } = useInstrument();
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const { dependencies, options, status } = useDependencies();

  return (
    <RegistrationSessionContainer>
      <ThemeProvider theme={theme}>
        <InstrumentContext.Provider value={contextValue}>
          <Container>
            <Body>
              <Sidebar ref={sidebarRef} />

              {status.pending && <Loading size="l" />}
              {status.succeed && (
                <Content>
                  <Routes>
                    <Route path={routes.LIST} element={<Placeholder />} />
                    <Route
                      path={routes.NEW}
                      element={
                        <InstrumentForm
                          dependencies={dependencies}
                          options={options}
                          onForceRefresh={() => sidebarRef.current?.refresh()}
                        />
                      }
                    />
                    <Route
                      path={routes.AS_NEW}
                      element={
                        <InstrumentForm
                          dependencies={dependencies}
                          options={options}
                          onForceRefresh={() => sidebarRef.current?.refresh()}
                        />
                      }
                    />
                    <Route
                      path={routes.EDIT}
                      element={
                        <InstrumentForm
                          dependencies={dependencies}
                          options={options}
                          onForceRefresh={(resetExpanded) =>
                            sidebarRef.current?.refresh(resetExpanded)
                          }
                        />
                      }
                    />
                  </Routes>
                </Content>
              )}
            </Body>
          </Container>
        </InstrumentContext.Provider>
      </ThemeProvider>
    </RegistrationSessionContainer>
  );
};

export default Instruments;
