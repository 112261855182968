import { object, string, array } from 'yup';

import {
  FeedStatus,
  InstantExecution,
  InstrumentBrokersProvider,
  InstrumentFeedsProvider,
  InstrumentSplit,
  OrderAutomation,
} from '~/types/models';
import {
  DateObject,
  DateTimeObject,
  YupError,
  YupErrorInner,
} from '~/types/shared';
import { EmptyNumber, EmptyTime, RecordSchema } from '~/utils/validation';

import { InstrumentData, InstrumentState, PatchedAccount } from './types';

const NumbersOnlyMessage = 'Field must be a number';
const URLOnlyMessage = 'Field must be a valid URL';
const InvalidDate = 'Invalid Date';

const dateSpec = {
  month: EmptyNumber(
    InvalidDate,
    { value: 0, msg: InvalidDate },
    { value: 13, msg: InvalidDate },
  ),
  year: EmptyNumber(
    InvalidDate,
    { value: 2000, msg: InvalidDate },
    { value: 3000, msg: InvalidDate },
  ),
  day: EmptyNumber(
    InvalidDate,
    { value: 0, msg: InvalidDate },
    { value: 32, msg: InvalidDate },
  ),
};

const dateObject = object<DateObject>(dateSpec);

const dateTimeObject = object<DateTimeObject>({
  ...dateSpec,
  time: EmptyTime('Invalid Time'),
});

const feedStatusSchema = object<FeedStatus>({
  url: string().url(URLOnlyMessage),
});

const instantExecutionSchema = object<InstantExecution>({
  maxDayPriceLimit: EmptyNumber(NumbersOnlyMessage),
  maxPositionVolume: EmptyNumber(NumbersOnlyMessage),
  maxPossibleDayDeviation: EmptyNumber(NumbersOnlyMessage),
  minDayPriceLimit: EmptyNumber(NumbersOnlyMessage),
  orderPriceMaxDeviation: EmptyNumber(NumbersOnlyMessage),
  quoteLifetime: EmptyNumber(NumbersOnlyMessage),

  markup: array(
    object({
      minMarkup: EmptyNumber(NumbersOnlyMessage),
      maxVolume: EmptyNumber(NumbersOnlyMessage),
      maxMarkup: EmptyNumber(NumbersOnlyMessage),
    }),
  ),

  markupOverrides: object({
    timeBased: array(
      object({
        duration: EmptyNumber(NumbersOnlyMessage),
        markup: array(
          object({
            minMarkup: EmptyNumber(NumbersOnlyMessage),
            maxVolume: EmptyNumber(NumbersOnlyMessage),
            maxMarkup: EmptyNumber(NumbersOnlyMessage),
          }),
        ),
      }),
    ),
  }),

  placementDelay: object({
    duration: EmptyNumber(NumbersOnlyMessage),
    maxDelay: EmptyNumber(NumbersOnlyMessage),
    minDelay: EmptyNumber(NumbersOnlyMessage),
  }),
});

const instrumentBrokersSchema = object<InstrumentData['brokers']>({
  accounts: RecordSchema<PatchedAccount>({
    constraints: object({
      maxQuantity: EmptyNumber(NumbersOnlyMessage),
      minQuantity: EmptyNumber(NumbersOnlyMessage),
    }),
  }),

  providerOverrides: RecordSchema<InstrumentBrokersProvider>({
    buyMarkup: EmptyNumber(NumbersOnlyMessage),
    contractMultiplier: EmptyNumber(NumbersOnlyMessage),
    legGap: EmptyNumber(NumbersOnlyMessage),
    markup: EmptyNumber(NumbersOnlyMessage),
    maturityDate: dateObject,
    minLotSize: EmptyNumber(NumbersOnlyMessage),
    priceMultiplier: EmptyNumber(NumbersOnlyMessage),
    sellMarkup: EmptyNumber(NumbersOnlyMessage),
    strikePriceMultiplier: EmptyNumber(NumbersOnlyMessage),
    volumeMultiplier: EmptyNumber(NumbersOnlyMessage),
  }),
});

const instrumentFeedsSchema = object<InstrumentData['feeds']>({
  providerOverrides: RecordSchema<InstrumentFeedsProvider>({
    bondCalcData: object({
      ACIDelta: EmptyNumber(NumbersOnlyMessage),
      bondDataMultipliers: object({
        ytmMultiplier: EmptyNumber(NumbersOnlyMessage),
        aciMultiplier: EmptyNumber(NumbersOnlyMessage),
      }),
      YTMLimits: object({
        max: EmptyNumber(NumbersOnlyMessage),
        min: EmptyNumber(NumbersOnlyMessage),
      }),
    }),

    generatorSettings: object({
      defaultSequence: array(
        object({
          count: EmptyNumber(NumbersOnlyMessage),
        }),
      ),

      sequences: array(
        object({
          from: string(),
          sequence: array(
            object({
              count: EmptyNumber(NumbersOnlyMessage),
            }),
          ),
          to: string(),
        }),
      ),

      deviation: EmptyNumber(NumbersOnlyMessage),
      initialPrice: EmptyNumber(NumbersOnlyMessage),
      maxDepth: EmptyNumber(NumbersOnlyMessage),
      maxPrice: EmptyNumber(NumbersOnlyMessage),
      maxSize: EmptyNumber(NumbersOnlyMessage),
      maxVariance: EmptyNumber(NumbersOnlyMessage),
      minDepth: EmptyNumber(NumbersOnlyMessage),
      minPrice: EmptyNumber(NumbersOnlyMessage),
      minSize: EmptyNumber(NumbersOnlyMessage),
      optionDataValueProbability: EmptyNumber(NumbersOnlyMessage),
      step: EmptyNumber(NumbersOnlyMessage),
    }),

    httpProperties: object({
      renewIntervalAuxData: EmptyNumber(NumbersOnlyMessage),
      renewIntervalBondData: EmptyNumber(NumbersOnlyMessage),
      renewIntervalOption: EmptyNumber(NumbersOnlyMessage),
      renewIntervalQuote: EmptyNumber(NumbersOnlyMessage),
    }),

    idcProperties: object({
      l2Source: EmptyNumber(NumbersOnlyMessage),
      source: EmptyNumber(NumbersOnlyMessage),
    }),

    nanexProperties: object({
      suspiciousTradeDeviation: EmptyNumber(NumbersOnlyMessage),
    }),

    reutersProperties: object({
      tradeMinPrice: EmptyNumber(NumbersOnlyMessage),
    }),

    syntheticSettings: object({
      maxSourceDeviation: EmptyNumber(NumbersOnlyMessage),
      sources: array(
        object({
          minSpread: EmptyNumber(NumbersOnlyMessage),
          quoteLifeTime: EmptyNumber(NumbersOnlyMessage),
        }),
      ),
    }),

    maturityDate: dateObject,
    askMarkup: EmptyNumber(NumbersOnlyMessage),
    bidMarkup: EmptyNumber(NumbersOnlyMessage),
    contractMultiplier: EmptyNumber(NumbersOnlyMessage),
    legGap: EmptyNumber(NumbersOnlyMessage),
    markup: EmptyNumber(NumbersOnlyMessage),
    maxQuoteDepth: EmptyNumber(NumbersOnlyMessage),
    minAllowedVolume: EmptyNumber(NumbersOnlyMessage),
    minSpreadMarkup: EmptyNumber(NumbersOnlyMessage),
    priceDeviation: EmptyNumber(NumbersOnlyMessage),
    priceMultiplier: EmptyNumber(NumbersOnlyMessage),
    quotePriceMultiplier: EmptyNumber(NumbersOnlyMessage),
    quoteVolumeMultiplier: EmptyNumber(NumbersOnlyMessage),
    restartOnAbsentQuotesTimeout: EmptyNumber(NumbersOnlyMessage),
    restartSubscriptionTimeout: EmptyNumber(NumbersOnlyMessage),
    strikePriceMultiplier: EmptyNumber(NumbersOnlyMessage),
    tradePriceMultiplier: EmptyNumber(NumbersOnlyMessage),
    tradeVolumeMultiplier: EmptyNumber(NumbersOnlyMessage),
    volumeMultiplier: EmptyNumber(NumbersOnlyMessage),
  }),
});

const orderAutomationSchema = object<OrderAutomation>({
  cancelEmulatedDayBeforeEndInterval: EmptyNumber(NumbersOnlyMessage),
  fallbackTimeout: EmptyNumber(NumbersOnlyMessage),
  marketEmulationDistance: EmptyNumber(NumbersOnlyMessage),
  minChunkSize: EmptyNumber(NumbersOnlyMessage),
  minPlaceInterval: EmptyNumber(NumbersOnlyMessage),
  pendingPlaceDelay: EmptyNumber(NumbersOnlyMessage),
  recurrentAttempts: EmptyNumber(NumbersOnlyMessage),
});

const instrumentSplitSchema = object<InstrumentSplit>({
  timestamp: EmptyNumber(NumbersOnlyMessage),
  coefficient: object({
    from: EmptyNumber(NumbersOnlyMessage),
    to: EmptyNumber(NumbersOnlyMessage),
  }),
});

const formSchema = object<InstrumentData>({
  splits: array(instrumentSplitSchema),

  instantExecution: instantExecutionSchema,
  brokers: instrumentBrokersSchema,
  feeds: instrumentFeedsSchema,
  feedStatus: feedStatusSchema,
  orderAutomation: orderAutomationSchema,

  defaultDate: dateObject,
  lastAvailable: dateTimeObject,
  lastTrading: dateTimeObject,

  expiry: dateTimeObject,

  name: string().required('Name is required'),

  closeOutPeriod: EmptyNumber(NumbersOnlyMessage),
  contractMultiplier: EmptyNumber(NumbersOnlyMessage),
  couponRate: EmptyNumber(NumbersOnlyMessage),
  delayFeedDepth: EmptyNumber(NumbersOnlyMessage),
  extremeLeverageRate: EmptyNumber(NumbersOnlyMessage),
  extremeLeverageRateShort: EmptyNumber(NumbersOnlyMessage),
  faceValue: EmptyNumber(NumbersOnlyMessage),
  feedMinPriceIncrement: EmptyNumber(NumbersOnlyMessage),
  initialMargin: EmptyNumber(NumbersOnlyMessage),
  intradayCoefficient: EmptyNumber(NumbersOnlyMessage),
  lastAvailableDev: EmptyNumber(NumbersOnlyMessage),
  lastTradingDev: EmptyNumber(NumbersOnlyMessage),
  leverageRate: EmptyNumber(NumbersOnlyMessage),
  leverageRateShort: EmptyNumber(NumbersOnlyMessage),
  lotSize: EmptyNumber(NumbersOnlyMessage),
  maintenanceMargin: EmptyNumber(NumbersOnlyMessage),
  maxCloseByMarketVolume: EmptyNumber(NumbersOnlyMessage),
  maxMarketOrderValue: EmptyNumber(NumbersOnlyMessage),
  maxPriceDeviation: EmptyNumber(NumbersOnlyMessage),
  minLotSize: EmptyNumber(NumbersOnlyMessage),
  minOrderQuantity: EmptyNumber(NumbersOnlyMessage),
  orderMinPriceIncrement: EmptyNumber(NumbersOnlyMessage),
  priceUnit: EmptyNumber(NumbersOnlyMessage),
  searchWeight: EmptyNumber(NumbersOnlyMessage),
  valueDateDelta: EmptyNumber(NumbersOnlyMessage),
});

const getErrors = (inner: YupErrorInner[]): Map<string, string> =>
  new Map(inner.map((err) => [err.path, err.message]));

const validate = (state: InstrumentState): InstrumentState => {
  try {
    formSchema.validateSync(state.values, { abortEarly: false });

    return {
      ...state,
      errors: new Map([]),
    };
  } catch (e) {
    const { inner } = e as YupError<InstrumentData>;

    return {
      ...state,
      errors: getErrors(inner),
    };
  }
};

export default validate;
