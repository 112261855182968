import { useEffect, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Checkbox, IconButton, Input } from 'react-ui-kit-exante';

import { DeleteDialog } from '~/components/DeleteDialog';

import { useCurrencyForm } from './hooks';
import {
  Actions,
  Container,
  CurrencyProps,
  Header,
  ImageContainer,
  Title,
} from './styled';
import { CurrencyFormProps } from './types';

const CurrencyForm = ({
  currencies,
  onClose,
  onDelete,
  onDirty,
  onSuccess,
}: CurrencyFormProps) => {
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  const {
    deletingStatus,
    form,
    handleDeleteCurrency,
    id,
    isDirty,
    isNew,
    isSaveDisabled,
    onSubmit,
    savingStatus,
    title,
  } = useCurrencyForm(currencies, onSuccess, onDelete, () =>
    setIsDeleteDialogShown(false),
  );

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  return (
    <>
      <FormProvider {...form}>
        <Container onSubmit={onSubmit}>
          <Header>
            <Title>{title}</Title>
            <Actions>
              <IconButton
                disabled={isSaveDisabled || savingStatus.isPending}
                iconColor="action"
                iconName="SaveIcon"
                iconSize={24}
                label="Save"
                type="submit"
              />

              {!isNew && (
                <IconButton
                  iconColor="radical"
                  iconName="DeleteIcon"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}

              <IconButton
                iconColor="secondary"
                iconName="CloseIcon"
                iconSize={24}
                onClick={onClose}
              />
            </Actions>
          </Header>

          <CurrencyProps>
            <Controller
              key="_id"
              name="_id"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  disabled={!!id}
                  error={Boolean(fieldState.error?.message)}
                  label="Name"
                  message={fieldState.error?.message}
                  onChange={field.onChange}
                  sx={{ width: '50%' }}
                  value={field.value || ''}
                />
              )}
            />
            <Controller
              key="icon"
              name="icon"
              control={form.control}
              render={({ field, fieldState }) => (
                <>
                  <Input
                    error={Boolean(fieldState.error?.message)}
                    placeholder="Icon"
                    message={
                      fieldState.error?.message && 'Icon should be a valid URL'
                    }
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                  {field.value && (
                    <ImageContainer>
                      <img alt="" src={field.value} width={50} height={50} />
                    </ImageContainer>
                  )}
                </>
              )}
            />
            <Controller
              key="description"
              name="description"
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Description"
                  sx={{ mt: '16px', width: '50%' }}
                  value={field.value || ''}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="leverageRate"
              name="leverageRate"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  error={Boolean(fieldState.error?.message)}
                  label="Leverage Rate *"
                  message={
                    fieldState.error?.message &&
                    'Leverage Rate should be integer'
                  }
                  sx={{ mt: '16px', width: '50%' }}
                  value={field.value || 0}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="leverageRateShort"
              name="leverageRateShort"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  error={Boolean(fieldState.error?.message)}
                  label="Leverage Rate Short *"
                  message={
                    fieldState.error?.message &&
                    'Leverage Rate Short should be integer'
                  }
                  sx={{ mt: '16px', width: '50%' }}
                  value={field.value || 0}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="extremeLeverageRate"
              name="extremeLeverageRate"
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Extreme Leverage Rate"
                  sx={{ mt: '16px', width: '50%' }}
                  value={field.value || 0}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="extremeLeverageRateShort"
              name="extremeLeverageRateShort"
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Extreme Leverage Rate Short"
                  sx={{ mt: '16px', width: '50%' }}
                  value={field.value || 0}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="fractionDigits"
              name="fractionDigits"
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Fraction Digits"
                  sx={{ mt: '16px', width: '50%' }}
                  value={field.value || 0}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="currencySymbol"
              name="currencySymbol"
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Currency Symbol"
                  sx={{ mt: '16px', width: '50%' }}
                  value={field.value || ''}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="weight"
              name="weight"
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Weight"
                  sx={{ mt: '16px', width: '50%' }}
                  value={field.value || 0}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="code"
              name="code"
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Code"
                  sx={{ mt: '16px', width: '50%' }}
                  value={field.value || 0}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="isNav"
              name="isNav"
              control={form.control}
              render={({ field }) => (
                <Checkbox
                  label="isNav"
                  sx={{ ml: '1px', mt: '16px', width: '50%' }}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              key="isCrypto"
              name="isCrypto"
              control={form.control}
              render={({ field }) => (
                <Checkbox
                  label="isCrypto"
                  sx={{ ml: '1px', mt: '16px', width: '50%' }}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </CurrencyProps>
        </Container>
      </FormProvider>

      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={handleDeleteCurrency}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default CurrencyForm;
